import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"

import Transition from '../components/transition/transition'
import StudienWrapper from '../components/pages/studies/studies';

export default (props) => {

  const studien = props.data.wpcontent.pageBy.studienfields;

  return (
    <Layout className="page__studien">
      <SEO title="Studien" />
      <Transition>
        <main>
          <StudienWrapper studien={studien} />
        </main>
      </Transition>
    </Layout>
  )
}

export const indexQuery = graphql`
  query studienQuery {
    wpcontent{
      pageBy(uri: "studien") {
        id
        studienfields {
          leistungsbeschrieb
          werkliste{
            title
            year
            location
            client
          }
          images {
            id
            sourceUrl
            mediaDetails {
              width
              height
              sizes {
                width
                height
                sourceUrl
              }
            }
          }
        }
      }
    }
  }
`


          // jobs

// allWordpressPage(filter: {slug: {glob: "buero"}}) {
//   edges {
//     node {
//       id
//       acf {
//         images {
//           id
//           source_url
//           localFile{
//             childImageSharp {
//               sizes {
//                   base64
//               }
//               resolutions {
//                   aspectRatio
//               }
//             }
//           }
//           media_details {
//             sizes {
//               thumbnail {
//                   source_url
//               }
//               medium {
//                   source_url
//               }
//               medium_large {
//                   source_url
//               }
//               wordpress_1536x1536 {
//                   source_url
//               }
//               wordpress_2048x2048 {
//                   source_url
//               }
//               full {
//                   source_url
//               }
//             }
//           }
//         }
//         biography
//         about_us {
//           label
//           text
//         }
//         jobs
//         employees {
//           first_name
//           name
//           position
//           title
//           email
//           alumni
//         }
//         werkliste {
//           url {
//             localFile {
//               url
//               publicURL
//             }
//           }
//         }
//         imprint {
//           label
//           text
//         }
//         copyright
//         liability
//       }
//     }
//   }
// }
// localFile{
//   childImageSharp {
//     resolutions {
//         height
//         width
//     }
//     fluid(maxWidth: 700){
//       ...GatsbyImageSharpFluid
//     }
//   }
// }

// localFile{
//   childImageSharp {
//     resolutions {
//         height
//         width
//     }
//     fluid(maxWidth: 700){
//       ...GatsbyImageSharpFluid
//     }
//   }
// }






// class InfoPage extends React.Component{
//   constructor(props){
//     super(props)

//     this.footer = {}
//     this.buero = {}
//     this.employeesShuffled = {}
//     this.images = [];
//     this.imagesTop    = []
//     this.imagesBottom = []
//     this.werkliste = false

//     // this.UNSAFE_componentWillMount() = this.UNSAFE_componentWillMount().bind(this)
//   }


//   UNSAFE_componentWillMount(){
//     this.buero = this.props.data.allWordpressPage.edges[0].node.acf;
//     this.employeesShuffled = shuffle(this.buero.employees)

//     this.footer = {
//       imprint: this.buero.imprint,
//       copyright: this.buero.copyright,
//       liability: this.buero.liability,
//     }

//     // const images = post.acf.images;
//     this.images = shuffle(this.buero.images);
//     this.images.map((image, i) => {
//       image.key = i
//       return image
//     })
//     this.imagesTop    = []
//     this.imagesBottom = []
//     // console.log(this.buero.werkliste)


//     this.images.forEach((image, i) => { 
//       if(i < this.images.length/2) this.imagesTop.push(image)
//       else this.imagesBottom.push(image)
//     })

//     this.werkliste = this.buero.werkliste.url.localFile ? this.buero.werkliste.url.localFile.publicURL : false

//   }
  
//   render(){
//     return (
//       <Layout className="page__buero">
//         <SEO title="Buero" />
//         <Transition>
//           <main>
//             <div className="page buero">
//               <div className="images" >
//                 <ImageHandler images={this.imagesTop} row={0}/>
//                 <ImageHandler images={this.imagesBottom} row={1}/>
//               </div>
                
//               <div className="wrapper">

//                 <div className="section exmployees">
//                   <Employees title="Mitarbeiter" employees={this.employeesShuffled} alumni={this.buero.employees}/>
//                 </div>
                
//                 <div className="section about">

//                   <div className="section__button">Über Uns</div>
                  
//                   {this.werkliste ? 
//                     <div className="section__button werkliste" >
//                       <a href={this.werkliste} download={true}>
//                         <div>Werkliste</div>
//                         <Werkliste />
//                       </a>
//                     </div> 
//                   : false }

//                   <About title="Über uns" about_us={this.buero.about_us} jobs={this.buero.jobs} biography={this.buero.biography} />
//                 </div>
//               </div>  

//               <Footer footer={this.footer} />
//             </div>  
//           </main>
//         </Transition>
//       </Layout>
//     )
//   }
// }
