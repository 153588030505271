import React from "react"

// import './buero.scss'
import style from './studies.module.scss'
import ImageHandler from '../../imageHandler/imageHandler'
import shuffle from '../../../helpers/shuffle'
import Toggler from './toggler'

export default ({studien}) => {
  let imagesTop    = []
  let imagesBottom = []


  const images = shuffle(studien.images);
  images.map((image, i) => {
    image.key = i
    return image
  })


  images.forEach((image, i) => { 
    if(i < images.length/2) imagesTop.push(image)
    else imagesBottom.push(image)
  })

  console.log(studien)

  return (
    <>
      <div className={`page ${style.studies}`}>
        <div className={style.images} >
            <ImageHandler images={imagesTop} row={0}/>
            <ImageHandler images={imagesBottom} row={1}/>
        </div>
            
        <div className={style.wrapper}>

            <div className={`${style.section} ${style.employees}`}>

              {/* <div className={style.aboutDesktop}>Leistungsbeschrieb</div> */}
              <Toggler title={`Leistungsbeschrieb`}>
                <div dangerouslySetInnerHTML={{__html: studien.leistungsbeschrieb }} className="paragraph"/>
              </Toggler>
            </div>
            
            <div className={`${style.section} ${style.about}`}>

              {/* <div className={style.aboutDesktop}>Werkliste Bereich Studien</div> */}
              <Toggler title={`Werkliste Bereich Studien`}>
                <div className={style.werkliste}>
                {
                  studien.werkliste.map((item, i) => {

                    return(
                      <div key={i} className={style.werklisteItem}>
                        <div dangerouslySetInnerHTML={{__html: item.title}} />
                        <div dangerouslySetInnerHTML={{__html: item.location}} />
                        <div dangerouslySetInnerHTML={{__html: item.client}} />
                        <div dangerouslySetInnerHTML={{__html: item.year}} />
                      </div>
                    )
                  })
                }
                </div>
              </Toggler>
              
              {/* {werkliste ? 
                  <div className={`${style.section__button} ${style.werkliste}`} >
                    <a href={werkliste} download={true}>
                        <div>Werkliste</div>
                        <Werkliste />
                    </a>
                  </div> 
              : false } */}

            </div>
          </div>  
        </div>  
    </>
  )
}

